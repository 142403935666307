.about__text
{
	width: 1172px;
	margin-bottom: 73px;

	font-size: 49px;
	line-height: 57px;
	font-weight: $bold;
	letter-spacing: 5px;
	text-transform: uppercase;
	text-align: center;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1680 )
	{
		width: 980px;

		font-size: 40px;
		line-height: 46px;
	}

	@media screen and ( $maxW_1440 )
	{
		width: 935px;
		margin-bottom: 50px;

		font-size: 38px;
		line-height: 44px;
	}

	@media screen and ( $maxW_1024 )
	{
		width: 764px;

		font-size: 32px;
		line-height: 37px;
		letter-spacing: 3px;
	}

	@media screen and ( $maxW_812 )
	{
		width: 630px;
		margin-bottom: 42px;

		font-size: 26px;
		line-height: 30px;
	}

	@media screen and ( $maxW_667 )
	{
		width: 320px;
		margin-bottom: 65px;

		font-size: 18px;
		line-height: 20px;
		letter-spacing: 2px;
	}
}