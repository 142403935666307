.partners__title
{
	margin-bottom: 47px;

	font-size: 38px;
	line-height: 41px;
	font-weight: $bold;
	text-transform: uppercase;
	letter-spacing: -1.9px;

	@media screen and ( $maxW_1024 )
	{
		margin-bottom: 23px;

		font-size: 28px;
		line-height: 31px;
		letter-spacing: -1.4px;
	}
}