.promo__details
{
	margin-left: 43px;

	font-size: 17px;
	line-height: 20px;
	font-weight: $medium;
	text-transform: uppercase;
	letter-spacing: 1.7px;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1920 )
	{
		align-self: center;

		margin-left: 0;

		font-size: calc( ( 17 - 12 ) / ( 1920 - 1280 ) * 100vw + 2px );
		line-height: calc( ( 20 - 14 ) / ( 1920 - 1280 ) * 100vw + 2px );
	}

	@media screen and ( $maxW_1280 )
	{
		font-size: 12px;
		line-height: 14px;
		letter-spacing: 1px;
	}

	@media screen and ( $minW_667 ) and ( $maxW_1024 )
	{
		display: none;
	}

	@media screen and ( $maxW_667 )
	{
		width: 288px;

		color: $clr_grey;
	}
}