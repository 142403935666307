.partners
{
	position: relative;
	z-index: 1;

	&::before
	{
		position: absolute;
		top: 0;
		bottom: -1px;
		left: calc(50% - 50vw);
		z-index: -1;

		width: 100vw;

		content: "";

		background: $clr_lightgrey;
	}
}