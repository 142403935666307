.modules__button
{
	@extend %button_orange;

	align-self: center;

	margin-top: 84px;

	@media screen and ( $maxW_1024 )
	{
		margin-top: 20px;
	}
}