@import "promo";
@import "promo__wrapper";
@import "promo__time";
@import "promo__begin";
@import "promo__date";
@import "promo__description";
@import "promo__description_black";
@import "promo__description_left";
@import "promo__description_right";
@import "promo__caption";
@import "promo__text";
@import "promo__title-wrapper";
@import "promo__info-wrapper";
@import "promo__details";
@import "promo__button";
@import "promo__photos";
@import "promo_preorder";