.promo_preorder
{
	z-index: 10;

	@media screen and ( $maxW_667 )
	{
		margin-bottom: -30px;
	}

	&::after
	{
		transform: skew(0deg, -3.6deg) translateY(-90px);

		@media screen and ( $maxW_1920 )
		{
			margin-top: -17px;

			transform: skew(0deg, -3.6deg) translateY(-90px);
		}

		@media screen and ( $maxW_1680 )
		{
			margin-top: 0;

			transform: skew(0deg, -3.6deg) translateY(-110px);
		}

		@media screen and ( $maxW_1280 )
		{
			transform: skew(0deg, -3.6deg) translateY(-121px);
		}

		@media screen and ( $maxW_1024 )
		{
			transform: skew(0deg, -3.6deg) translateY(-231px);
		}

		@media screen and ( $maxW_812 )
		{
			transform: skew(0deg, -3.6deg) translateY(-236px);
		}

		@media screen and ( $maxW_667 )
		{
			transform: skew(0deg, -3.6deg) translateY(-371px);
		}
	}
}

.promo_preorder-counter
{
	z-index: 10;

	@media screen and ( $maxW_667 )
	{
		margin-bottom: -30px;
	}

	&::after
	{
		transform: skew(0deg, -3.6deg) translateY(-220px);

		@media screen and ( $maxW_1920 )
		{
			margin-top: -17px;

			transform: skew(0deg, -3.6deg) translateY(-220px);
		}

		@media screen and ( $maxW_1680 )
		{
			margin-top: 0;

			transform: skew(0deg, -3.6deg) translateY(-230px);
		}

		@media screen and ( $maxW_1280 )
		{
			transform: skew(0deg, -3.6deg) translateY(-250px);
		}

		@media screen and ( $maxW_1024 )
		{
			transform: skew(0deg, -3.6deg) translateY(-360px);
		}

		@media screen and ( $maxW_812 )
		{
			transform: skew(0deg, -3.6deg) translateY(-365px);
		}

		@media screen and ( $maxW_667 )
		{
			transform: skew(0deg, -3.6deg) translateY(-500px);
		}
	}
}

.promo__subscribe
{
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	align-self: center;
	justify-content: center;

	width: 950px;
	padding-top: 50px;

	@media screen and ($maxW_1024)
	{
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		width: 100%;
	}

	@media screen and ( $maxW_667 )
	{
		order: 10;

		margin-top: 50px;
	}
}

.promo__subscribe_end
{
	width: 100%;
}

.promo__subscribe_success
{
	.promo__subscribe-wrapper
	{
		position: relative;

		&::after
		{
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;

			content: "Подписка оформлена";

			font-size: 18px;
			line-height: 22px;
			font-weight: $medium;
			text-transform: uppercase;
			letter-spacing: 2px;

			color: $clr_lightgrey;

			@media screen and ($maxW_1024)
			{
				top: 0;
				right: 0;
				bottom: auto;

				text-align: center;
			}
		}
	}

	.promo__subscribe-input
	{
		opacity: 0;
	}
}

.promo__subscribe_error
{
	.promo__subscribe-wrapper
	{
		position: relative;

		&::after
		{
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;

			content: "Произошла ошибка";

			font-size: 18px;
			line-height: 22px;
			font-weight: $medium;
			text-transform: uppercase;
			letter-spacing: 2px;

			color: $clr_lightgrey;

			@media screen and ($maxW_1024)
			{
				top: 0;
				right: 0;
				bottom: auto;

				text-align: center;
			}
		}
	}

	.promo__subscribe-input
	{
		opacity: 0;
	}
}

.promo__subscribe-text
{
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	margin-right: 102px;

	font-size: 20px;
	line-height: 27px;
	font-weight: $medium;
	text-transform: uppercase;
	letter-spacing: 2px;

	@media screen and ( $maxW_1920 )
	{
		font-size: calc( ( 20 - 14 ) / ( 1920 - 1280 ) * 100vw + 2px );
		line-height: calc( ( 27 - 19 ) / ( 1920 - 1280 ) * 100vw + 3px );
	}

	@media screen and ( $maxW_1280 )
	{
		font-size: 14px;
		line-height: 19px;
		letter-spacing: 1.5px;
	}

	@media screen and ( $maxW_1024 )
	{
		margin-right: 0;

		font-size: 0;
		line-height: 0;
		letter-spacing: 0;
	}

	span
	{
		position: relative;
		z-index: 1;

		&:nth-child(1)
		{
			&::before
			{
				top: 0;
				left: -2%;
				width: 102%;

				@media screen and ( $maxW_1024 )
				{
					width: 106%;
				}
			}
		}

		&:nth-child(2)
		{
			&::before
			{
				top: 0;
				left: -1.5%;

				width: 117%;

				@media screen and ( $maxW_1024 )
				{
					width: 106%;
				}
			}
		}

		&:nth-child(3)
		{
			&::before
			{
				top: 0;
				left: -0.5%;
				width: 102%;

				@media screen and ( $maxW_1024 )
				{
					width: 106%;
				}
			}
		}

		&:nth-child(4)
		{
			&::before
			{
				top: 0;
				left: -1.5%;

				width: 104%;
			}
		}

		&:nth-child(5)
		{
			&::before
			{
				top: 0;
				left: -2%;
				width: 112%;

				@media screen and ( $maxW_1024 )
				{
					width: 106%;
				}
			}
		}

		&::before
		{
			position: absolute;
			z-index: -1;

			width: 100%;
			height: 85%;

			content: "";

			background-color: $clr_orange;
		}

		&::after
		{
			@media screen and ($maxW_1024)
			{
				display: inline;

				content: attr(data-short);

				font-size: 14px;
				line-height: 19px;
				letter-spacing: 1.5px;
				white-space: nowrap;
			}
		}
	}
}

.promo__subscribe-text_end
{
	@media screen and ( $maxW_1024 )
	{
		font-size: 14px;
		line-height: 19px;
		letter-spacing: 1.5px;
	}

	@media screen and ( $maxW_667 )
	{
		font-size: 0;
		line-height: 0;
		letter-spacing: 0;
	}

	> span
	{
		@media screen and ( $maxW_667 )
		{
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		&::before,
		&::after
		{
			@media screen and ( $maxW_667 )
			{
				position: static;

				width: auto !important;
				height: auto !important;
				margin-bottom: 2px;
				//content: attr(data-short);

				font-size: 14px;
				line-height: 19px;
				letter-spacing: 1.5px;
				white-space: nowrap;

				background-color: $clr_orange;
			}
		}

		&::before
		{
			@media screen and ( $maxW_667 )
			{
				content: attr(data-short-1);
			}
		}

		&::after
		{
			@media screen and ( $maxW_667 )
			{
				content: attr(data-short-2);
			}
		}
	}
}

.promo__subscribe-text-wrapper_mobile
{
	@media screen and ( $maxW_1024 )
	{
		display: none;
	}
}

.promo__subscribe-wrapper
{
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	width: 288px;

	@media screen and ( $maxW_1024 )
	{
		margin-top: 40px;
	}

	@media screen and ( $maxW_667)
	{
		align-items: center;

		width: 100%;
	}
}

.promo__subscribe-input
{
	width: 100%;
	padding-bottom: 9px;
	box-sizing: border-box;

	font-size: 16px;
	line-height: 19px;
	font-weight: $medium;
	text-transform: uppercase;
	letter-spacing: 2px;

	background-color: transparent;
	color: $clr_lightgrey;

	border: none;
	border-bottom: 3px solid $clr_white;

	&::placeholder
	{
		color: $clr_lightgrey;
	}
}

.promo__subscribe-button
{
	@extend %button_orange;

	order: -1;

	margin-bottom: 40px;

	@media screen and ( $maxW_1024 )
	{
		order: 0;

		margin-top: 16px;
		margin-bottom: 0;
	}
}

.promo__subscribe-button_fixed
{
	@media screen and ( $maxW_1024)
	{
		position: fixed;
		top: 20px;
		z-index: 10;

		margin: 0 auto;
	}
}

.promo__subscribe-counter-wrapper
{
	display: flex;
	flex-direction: column;

	width: 100%;
	padding-top: 40px;

	font-size: 18px;
	line-height: 20px;
	font-weight: $bold;
	text-transform: uppercase;
	letter-spacing: 2px;
	text-align: center;
	white-space: nowrap;

	color: $clr_lightgrey;
}

.promo__subscribe-counter
{
	font-size: 62px;
	line-height: 69px;
	font-weight: $bold;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.promo__preorder-link
{
	@extend %button_orange;

	order: 2;
	align-self: center;

	margin-bottom: 50px;

	@media screen and ($minW_667)
	{
		display: none;
	}
}