.header
{
	position: fixed;
	top: 87px;
	right: calc((100vw - 1277px) / 2 - 50px);
	z-index: 50;

	@media screen and ($maxW_1920)
	{
		top: calc( ( 87 - 57 ) / ( 1920 - 1280 ) * 100vw - 3px );
		//right: calc( ( 314 - 57 ) / ( 1920 - 1280 ) * 100vw - 457px );
	}

	@media screen and ($maxW_1680)
	{
		right: calc((100vw - 1097px) / 2 - 50px);
	}

	@media screen and ($maxW_1280)
	{
		top: 57px;
		right: calc((100vw - 944px) / 2 - 50px);
	}

	@media screen and ($maxW_1024)
	{
		top: 18px;
		right: calc((100vw - 748px) / 2);
	}

	@media screen and ($maxW_812)
	{
		right: calc((100vw - 672px) / 2);
	}

	@media screen and ($maxW_736)
	{
		right: calc((100vw - 617px) / 2);
	}

	@media screen and ($maxW_736)
	{
		right: calc((100vw - 617px) / 2);
	}

	@media screen and ($maxW_667)
	{
		right: calc((100vw - 325px) / 2);
	}

	@media screen and ($maxW_414)
	{
		right: calc((100vw - ( 100% - 16px * 2 )) / 2);
	}
}

.header_show
{
	top: 0 !important;
	bottom: 0 !important;
	left: 0 !important;
	right: 0 !important;

	overflow-y: scroll;

	background-color: $clr_lightgrey;
}

.header__nav
{
	display: none;

	width: 100%;
	min-height: 100%;

	background: 50% calc(50% + 116px) url("/img/about/icons.svg") no-repeat;

	@media screen and ( $maxW_1680 )
	{
		background-size: 1100px;
		background-position: 50% calc(50% + 97px);
	}

	@media screen and ( $maxW_1280 )
	{
		background: 50% calc(50% + 103px) url("/img/about/icons_667.svg") no-repeat;
	}

	@media screen and ( $maxW_1024 )
	{
		background: calc(50% - 4px) calc(50% + 76px) url("/img/about/icons_320.svg") no-repeat;
	}
}

.header__nav_show
{
	display: flex;
	align-items: flex-start;
}

.header__list
{
	padding: 20px 0;
	margin: auto;

	@media screen and ( $maxW_1024 )
	{
		width: 230px;
		padding: 67px 0;
	}
}

.header__item
{
	&:not(:last-child)
	{
		margin-bottom: 12px;
	}
}

.header__item_center
{
	text-align: center;

	@media screen and ( $maxW_667 )
	{
		display: flex;
		align-items: flex-start;
		justify-content: center;
	}
}

.header__link
{
	font-size: 28px;
	line-height: 32px;
	font-weight: $bold;
	letter-spacing: 3px;
	text-transform: uppercase;

	color: $clr_black;

	transition: color 0.2s ease-in-out;

	@media screen and ($maxW_1680)
	{
		font-size: 18px;
		line-height: 20px;
		letter-spacing: 2px;
	}

	&:hover
	{
		color: $clr_orange;
	}
}

.header__button
{
	@extend %button_orange;

	margin-top: 42px;

	@media screen and ($maxW_1680)
	{
		margin-top: 25px;
	}

	@media screen and ($maxW_1280)
	{
		margin-top: 42px;
	}

	@media screen and ($maxW_1024)
	{
		margin-top: 30px;
	}
}

.header__show-button
{
	position: relative;

	width: 35px;
	height: 28px;

	font-size: 0;
	line-height: 1;

	background: transparent;

	border: none;

	&::before,
	&::after
	{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		height: 5px;
		margin: auto 0;

		background: $clr_grey;
	}

	&::before
	{
		content: "";

		box-shadow: 0px -12px 0px 0px $clr_grey, 0px 12px 0px 0px $clr_grey;
	}
}

.header__show-button_close
{
	position: fixed;
	top: 87px;
	right: calc((100vw - 1277px) / 2 - 36px);

	@media screen and ($maxW_1920)
	{
		top: calc( ( 87 - 57 ) / ( 1920 - 1280 ) * 100vw - 3px );
	}

	@media screen and ($maxW_1680)
	{
		right: calc((100vw - 1097px) / 2 - 36px);
	}

	@media screen and ($maxW_1280)
	{
		top: 57px;
		right: calc((100vw - 944px) / 2 - 36px);
	}

	@media screen and ($maxW_1024)
	{
		top: 18px;
		right: calc((100vw - 748px) / 2);
	}

	@media screen and ($maxW_812)
	{
		right: calc((100vw - 672px) / 2);
	}

	@media screen and ($maxW_736)
	{
		right: calc((100vw - 617px) / 2);
	}

	@media screen and ($maxW_736)
	{
		right: calc((100vw - 617px) / 2);
	}

	@media screen and ($maxW_667)
	{
		right: calc((100vw - 325px) / 2);
	}

	@media screen and ($maxW_414)
	{
		right: calc((100vw - ( 100% - 16px * 2 )) / 2);
	}

	&::before,
	&::after
	{
		margin: auto;
	}

	&::before
	{
		box-shadow: none;

		transform: rotate(45deg);
	}

	&::after
	{
		content: "";

		transform: rotate(-45deg);
	}
}