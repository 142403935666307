.tickets-short_preorder
{
	background-image: url("/img/tickets-short/r_orange.svg");

	@media screen and ( $maxW_1024 )
	{
		padding-top: 0;
		margin-bottom: 84px;
	}

	&::before,
	&::after
	{
		content: none;
	}
}