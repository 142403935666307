.promo__time
{
	display: flex;
	flex-direction: column;
	align-self: flex-end;

	margin-bottom: 112px;
	margin-right: 318px;

	@media screen and ( $maxW_1920 )
	{
		margin-bottom: calc( ( 112 - 77 ) / ( 1920 - 1280 ) * 100vw - 7px );
		margin-right: calc( ( 318 - 84 ) / ( 1920 - 1280 ) * 100vw - 384px );
	}

	@media screen and ( $maxW_1280 )
	{
		margin-bottom: 77px;
		margin-right: 198px;
	}

	@media screen and ( $maxW_1024 )
	{
		margin-right: 60px;
		margin-bottom: 35px;
	}

	@media screen and ( $maxW_812 )
	{
		margin-bottom: 28px;
	}

	@media screen and ( $maxW_736 )
	{
		margin-bottom: 54px;
	}

	@media screen and ( $maxW_667 )
	{
		order: 1;
		align-self: flex-start;

		margin-bottom: 40px;
		margin-right: 0;
	}
}