.reviews__main-text
{
	width: 690px;
	margin-bottom: 4px;

	font-size: 14px;
	line-height: 19px;
	font-weight: $medium;
	letter-spacing: 1.4px;
	text-transform: uppercase;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1024 )
	{
		width: 590px;
		margin-bottom: 1px;

		font-size: 12px;
		line-height: 14px;
		letter-spacing: 1.1px;
	}

	@media screen and ( $maxW_667 )
	{
		width: 100%;
		margin-bottom: 4px;
	}
}