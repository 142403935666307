.modules__title
{
	font-size: 99px;
	line-height: 97px;
	font-weight: $bold;
	letter-spacing: -5px;
	text-transform: uppercase;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1280 )
	{
		font-size: 0;
		line-height: 0;
	}

	&::before
	{
		@media screen and ( $maxW_1280 )
		{
			display: table-caption;

			font-size: 99px;
			line-height: 97px;
			font-weight: $bold;
			letter-spacing: -5px;
			text-transform: uppercase;

			content: attr(data-short);
		}

		@media screen and ( $maxW_1024 )
		{
			font-size: 62px;
			line-height: 69px;
			letter-spacing: -3px;
		}

		@media screen and ( $maxW_667 )
		{
			font-size: 38px;
			line-height: 41px;
			letter-spacing: -2px;
		}
	}
}