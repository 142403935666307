.creators
{
	@extend %default_grid;

	display: flex;
	align-items: center;
	flex-direction: column;

	margin-bottom: 100px;

	@media screen and ( $maxW_1024 )
	{
		margin-bottom: 47px;
	}

	@media screen and ( $maxW_812 )
	{
		margin-bottom: 35px;
	}

	@media screen and ( $maxW_667 )
	{
		margin-bottom: 47px;
	}
}