.creators__list
{
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	width: 847px;

	@media screen and ( $maxW_1680 )
	{
		width: 906px;
	}

	@media screen and ( $maxW_1280 )
	{
		width: 820px;
	}

	@media screen and ( $maxW_1024 )
	{
		width: 696px;
	}

	@media screen and ( $maxW_812 )
	{
		width: 616px;
	}

	@media screen and ( $maxW_667 )
	{
		justify-content: flex-start;
		flex-direction: column;
		align-items: flex-start;

		width: 100%;
	}
}

.creators__item
{
	width: 411px;

	@media screen and ( $maxW_1680 )
	{
		width: 436px;
	}

	@media screen and ( $maxW_1280 )
	{
		width: 406px;
	}

	@media screen and ( $maxW_1024 )
	{
		width: 336px;
	}

	@media screen and ( $maxW_812 )
	{
		width: 293px;
	}

	@media screen and ( $maxW_667 )
	{
		width: 100%;
	}

	&:nth-child(1)
	{
		@media screen and ( $maxW_667 )
		{
			margin-bottom: 46px;
		}
	}
}

.creators__caption
{
	margin-bottom: 19px;

	font-size: 38px;
	line-height: 41px;
	font-weight: $bold;
	letter-spacing: -2px;
	text-transform: uppercase;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1024 )
	{
		font-size: 28px;
		line-height: 31px;
		letter-spacing: -1.5px;
	}
}

.creators__achievement-list
{

}

.creators__achievement-item
{
	font-size: 12px;
	line-height: 14px;
	font-weight: $bold;
	letter-spacing: 1.3px;
	text-transform: uppercase;

	color: $clr_lightgrey;

	&::before
	{
		display: inline;

		content: "—";
	}

	&:not(:last-child)
	{
		margin-bottom: 14px;
	}
}