.reviews
{
	@extend %default_grid;

	position: relative;
	z-index: 1;

	display: flex;
	align-items: flex-start;
	flex-direction: column;

	padding-bottom: 83px;
}