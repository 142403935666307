.creators__main-text
{
	width: 756px;
	margin-bottom: 80px;

	font-size: 28px;
	line-height: 32px;
	font-weight: $bold;
	letter-spacing: 2px;
	text-transform: uppercase;
	text-align: center;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1024 )
	{
		display: none;
	}
}