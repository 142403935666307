.modules
{
	@extend %default_grid;

	position: relative;
	z-index: 1;

	display: flex;
	flex-direction: column;
	align-items: flex-start;

	margin-bottom: 106px;

	@media screen and ( $maxW_1280 )
	{
		margin-bottom: 112px;
	}

	@media screen and ( $maxW_1024 )
	{
		margin-bottom: 50px;
	}
}