.reviews__nav-wrapper
{
	position: relative;

	width: 100%;

	transform: translateY(-60px);

	@media screen and ( $maxW_1024 )
	{
		order: 1;
		display: flex;
		align-items: flex-start;
		justify-content: center;

		margin-top: 6px;

		transform: translateY(0);
	}
}


.reviews__nav
{
	position: absolute;
	bottom: 11px;
	right: 22px;

	display: flex;
	align-items: center;

	@media screen and ( $maxW_1680 )
	{
		right: -10px;
	}

	@media screen and ( $maxW_1280 )
	{
		right: -23px;
	}

	@media screen and ( $maxW_1024 )
	{
		position: static;
	}
}

.reviews__nav_mobile
{
	position: static;
}

.reviews__nav-button
{
	position: relative;

	width: 50px;
	height: 50px;

	font-size: 0;

	background-color: transparent;

	border: none;

	&:not([disabled])
	{
		svg path
		{
			fill: $clr_orange;
		}
	}

	svg path
	{
		transition: fill 0.2s ease-in-out;
	}
}

.reviews__nav-button_next
{
	transform: rotate(180deg);
}

.reviews__nav-button_prev
{

}

.reviews__progress-bar
{
	overflow: hidden;

	position: relative;

	width: 202px;
	height: 4px;

	background-color: $clr_lightgrey;

	@media screen and ( $maxW_1024 )
	{
		background-color: $clr_black;
	}
}

.reviews__bar
{
	position: absolute;
	top: 0;
	left: 0;

	width: calc(202px / 6 * 4);
	height: inherit;

	background-color: $clr_orange;

	transition: left 0.3s ease-in-out;

	@media screen and ($maxW_1024)
	{
		width: calc(202px / 6 * 2);
	}

	@media screen and ($maxW_667)
	{
		width: calc(202px / 6);
	}
}