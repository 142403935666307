input
{
	border-radius: 0;

	&[type="checkbox"],
	&[type="radio"]
	{
		display: none;
	}
}
