.modules__subscribe
{
	align-self: center;

	margin-top: 84px;

	@media screen and ($maxW_1024)
	{
		display: none;
	}
}

.modules__subscribe-wrapper
{

}

.modules__subscribe_success
{
	.modules__subscribe-wrapper
	{
		position: relative;

		&::after
		{
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			z-index: 1;

			display: flex;
			align-items: center;

			content: "Подписка оформлена";

			font-size: 18px;
			line-height: 22px;
			font-weight: $medium;
			text-transform: uppercase;
			letter-spacing: 2px;

			color: $clr_lightgrey;
		}
	}

	.modules__subscribe-input
	{
		opacity: 0;
	}
}

.modules__subscribe_error
{
	.modules__subscribe-wrapper
	{
		position: relative;

		&::after
		{
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			z-index: 1;

			display: flex;
			align-items: center;

			content: "Произошла ошибка";

			font-size: 18px;
			line-height: 22px;
			font-weight: $medium;
			text-transform: uppercase;
			letter-spacing: 2px;

			color: $clr_lightgrey;
		}
	}

	.modules__subscribe-input
	{
		opacity: 0;
	}
}

.modules__subscribe-input
{
	width: 287px;
	margin-right: 39px;
	padding-bottom: 9px;
	box-sizing: border-box;

	font-size: 16px;
	line-height: 19px;
	font-weight: $medium;
	text-transform: uppercase;
	letter-spacing: 2px;

	background-color: transparent;
	color: $clr_lightgrey;

	border: none;
	border-bottom: 3px solid $clr_white;

	&::placeholder
	{
		color: $clr_lightgrey;
	}
}

.modules__subscribe-button
{
	@extend %button_orange;
}