@import "components-wrapper";
@import "promo/index";
@import "training/index";
@import "modules/index";
@import "promo-text/index";
@import "program/index";
@import "lecturer/index";
@import "tickets/index";
@import "about/index";
@import "creators/index";
@import "reviews/index";
@import "partners/index";
@import "tickets-short/index";
@import "photos/index";
@import "success/index";