.about_preorder
{
	padding-top: 50px;
	margin-bottom: 0;

	background-position: 50% 200px;

	@media screen and ( $maxW_1680 )
	{
		background-position: 50% 170px;
	}

	@media screen and ( $maxW_1440 )
	{
		background-position: 50% 170px;
	}

	@media screen and ( $maxW_1280 )
	{
		background-position: 50% 170px;
	}

	@media screen and ( $maxW_1024 )
	{
		margin-bottom: 84px;

		background-position: 50% 136px;
	}

	@media screen and ( $maxW_812 )
	{
		background-position: 50% 105px;
	}

	@media screen and ( $maxW_667 )
	{
		padding-top: 0;

		background-position: 50% 70px;
	}
}

.about__background_preorder
{
	display: none;
}