.tickets-short__notice
{
	position: relative;

	display: flex;
	align-items: flex-start;
	flex-direction: column;
	align-self: center;

	width: 357px;
	box-sizing: border-box;
	padding-bottom: 12px;
	margin-bottom: 100px;

	background-color: $clr_lightgrey;

	transform: rotate(1deg);

	@media screen and ( $maxW_1024 )
	{
		order: 1;

		width: 308px;
		margin-top: 36px;
		margin-bottom: 0;
		padding-bottom: 42px;
	}

	@media screen and ( $maxW_667 )
	{
		order: -1;

		margin-top: 0;
		margin-bottom: 31px;
	}
}

.tickets-short__notice_preorder
{
	width: 357px;
}

.tickets-short__date
{
	margin-top: -5px;
	margin-left: -2px;
	margin-bottom: 4px;

	font-size: 28px;
	line-height: 32px;
	font-weight: $bold;
	letter-spacing: 3px;
	text-transform: uppercase;
	white-space: nowrap;

	@media screen and ( $maxW_1024 )
	{
		display: none;
	}
}

.tickets-short__text
{
	margin-left: -1px;

	font-size: 12px;
	line-height: 14px;
	font-weight: $medium;
	letter-spacing: 1.3px;
	text-transform: uppercase;

	&:nth-child(2)
	{
		margin-bottom: 14px;

		@media screen and ( $maxW_1024 )
		{
			margin-bottom: 2px;

			font-size: 18px;
			line-height: 20px;
			font-weight: $bold;
		}
	}
}