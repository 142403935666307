.lecturer__wrapper
{
	position: relative;
	z-index: 1;

	width: 560px;
	padding-bottom: 228px;

	@media screen and ( $maxW_1024 )
	{
		padding-bottom: 0;
	}

	@media screen and ( $maxW_667 )
	{
		width: 100%;
	}

	&::before
	{
		position: absolute;
		top: 0;
		left: calc( 50% - 50vw );
		right: 0;
		bottom: 0;
		z-index: -1;

		content: "";

		background: linear-gradient(90deg, rgba(0,0,0,0.5) 80%, rgba(0,0,0,0) 100%);

		@media screen and ( $maxW_1024 )
		{
			background: linear-gradient(90deg, rgba(0,0,0,0.5) 60%, rgba(0,0,0,0) 100%);
		}

		@media screen and ( $maxW_667 )
		{
			right: calc( 50% - 50vw );
			left: 0;

			background: linear-gradient(-90deg, rgba(0,0,0,0.5) 60%, rgba(0,0,0,0) 100%);
		}
	}
}