.lecturer__pre-title
{
	display: block;

	margin-bottom: 10px;

	font-size: 14px;
	line-height: 19px;
	font-weight: $medium;
	letter-spacing: 1.4px;
	text-transform: uppercase;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1024 )
	{
		margin-bottom: -3px;

		font-size: 12px;
		line-height: 14px;
	}

	@media screen and ( $maxW_667 )
	{
		margin-bottom: 3px;

		text-align: right;
	}
}