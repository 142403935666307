body
{
	position: relative;

	display: flex;
	flex-direction: column;
	overflow: hidden;

	width: 100%;
	min-width: $min_width;
	min-height: 100vh;

	font-family: $gotham_pro;
	font-weight: $medium;

	-webkit-text-size-adjust: none;
	-webkit-tap-highlight-color: rgba( 0, 0, 0, 0 );
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	//background: $clr_lightgrey;
	background: $clr_black;
	color: $clr_black;
}

.success-page
{
	background: $clr_lightgrey;
}