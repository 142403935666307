.program__title
{
	width: 351px;
	margin-right: 125px;
	margin-left: -45px;
	box-sizing: border-box;
	padding: 67px 0 0 45px;

	font-size: 62px;
	line-height: 69px;
	font-weight: $bold;
	letter-spacing: -3px;
	text-transform: uppercase;

	background: 0 0 url("/img/program/dsgn.svg") no-repeat;
	color: $clr_lightgrey;

	@media screen and ( $maxW_1680 )
	{
		margin-right: 32px;
	}

	@media screen and ( $maxW_1280 )
	{
		margin-right: 24px;
	}

	@media screen and ( $maxW_1024 )
	{
		width: 100%;
		padding: 0;
		margin-left: 0;
		margin-right: 0;

		background: none;
	}

	@media screen and ( $maxW_667 )
	{
		width: 187px;

		font-size: 38px;
		line-height: 41px;
		letter-spacing: -2px;
	}
}