.promo__begin
{
	margin-bottom: -2px;

	font-size: 14px;
	line-height: 19px;
	font-weight: $medium;
	text-transform: uppercase;
	letter-spacing: 2px;

	@media screen and ( $maxW_1680 )
	{
		margin-bottom: 0;

		font-size: 12px;
		line-height: 14px;
	}
}