.tickets__title
{
	margin-bottom: 54px;

	font-size: 99px;
	line-height: 97px;
	font-weight: $bold;
	letter-spacing: -5px;
	text-transform: uppercase;
	text-align: center;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1680 )
	{
		text-align: left;
	}

	@media screen and ( $maxW_1024 )
	{
		margin-bottom: 22px;

		font-size: 62px;
		line-height: 69px;
		letter-spacing: -3px;
	}

	@media screen and ( $maxW_667 )
	{
		font-size: 38px;
		line-height: 41px;
	}
}

.tickets__title_upgrade
{
	padding-left: 40px;

	text-align: left;

	@media screen and ( $maxW_1280 )
	{
		padding-left: 0;
	}
}