.promo__title-wrapper
{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: flex-end;

	margin-bottom: 36px;

	@media screen and ( $maxW_1920 )
	{
		margin-bottom: calc( ( 36 - 22 ) / ( 1920 - 1280 ) * 100vw - 6px );
	}

	@media screen and ( $maxW_1280)
	{
		align-self: center;
		align-items: center;

		margin-bottom: 22px;
	}

	@media screen and ( $maxW_1024 )
	{
		width: 100%;
		margin-bottom: 12px;
	}

	@media screen and ( $maxW_736 )
	{
		margin-bottom: 8px;
	}

	@media screen and ( $maxW_667 )
	{
		order: 2;

		margin-bottom: 281px;

		text-align: center;
	}

	@media screen and ( $maxW_414 )
	{
		margin-bottom: calc( ( 281 - 239 ) / ( 414 - 320 ) * 100vw + 96px );
	}
}

.promo__title-wrapper_upgrade
{
	@media screen and ($maxW_667)
	{
		margin-top: 74px;
	}
}

.promo__title-wrapper_end
{
	text-align: center;
}