.promo__button
{
	@extend %button_orange;

	align-self: flex-end;

	margin-right: 276px;

	@media screen and ( $maxW_1920 )
	{
		margin-right: calc( ( 276 - 125 ) / ( 1920 - 1280 ) * 100vw - 177px );
	}

	@media screen and ( $maxW_1280 )
	{
		margin-right: 150px;
	}

	@media screen and ( $maxW_1024 )
	{
		align-self: center;

		margin-right: 0;
	}

	@media screen and ( $maxW_667 )
	{
		order: 3;

		margin-bottom: 50px;
	}
}