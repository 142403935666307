.promo__date
{
	font-size: 28px;
	line-height: 32px;
	font-weight: $bold;
	text-transform: uppercase;
	letter-spacing: 2px;

	@media screen and ( $maxW_1680 )
	{
		font-size: 18px;
		line-height: 20px;
	}
}