$retina_dpi: 144dpi;
$retina_dppx: 1.5dppx;

$min_width: 320px;

$width: 1277px;

$width_maxW_1680: 1097px;

$width_maxW_1280: 944px;

$width_maxW_1024: 748px;

$width_maxW_812: 672px;

$width_maxW_736: 617px;

$width_maxW_667: calc( 100% - 16px * 2 );
$max_width_maxW_667: 325px;