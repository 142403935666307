.training__description-text
{
	font-size: 12px;
	line-height: 14px;
	font-weight: $medium;
	letter-spacing: 1.2px;
	text-transform: uppercase;

	color: $clr_lightgrey;
}
