.training__description-caption
{
	margin-bottom: 4px;

	font-size: 28px;
	line-height: 32px;
	font-weight: $bold;
	letter-spacing: 3px;
	text-transform: uppercase;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1024 )
	{
		font-size: 18px;
		line-height: 20px;
	}

	@media screen and ( $maxW_667 )
	{
		display: table-caption;

		font-size: 28px;
		line-height: 32px;
	}
}