.program
{
	@extend %default_grid;

	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;

	margin-bottom: 135px;

	@media screen and ( $maxW_1680 )
	{
		margin-bottom: 100px;
	}

	@media screen and ( $maxW_1024 )
	{
		margin-bottom: 80px;
	}
}