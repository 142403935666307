.tickets-short
{
	position: relative;

	overflow: hidden;

	padding-top: 356px;
	padding-bottom: 230px;

	background: center 170px url("/img/tickets-short/r.svg") no-repeat;

	@media screen and ( $maxW_1024 )
	{
		padding-top: 144px;
		padding-bottom: 0;

		background: none;
	}

	&::before
	{
		position: absolute;
		top: 0;
		bottom: 0;
		right: calc(50% - 60vw);
		left: 50.1%;
		z-index: -2;

		content: "";

		background-color: $clr_orange;

		@media screen and ( $maxW_1024 )
		{
			content: none;
		}
	}

	&::after
	{
		position: absolute;
		bottom: calc(100% - 48px);
		right: -100vw;
		z-index: -1;

		width: 200vw;
		height: calc(1vw + 200px);

		content: "";

		background: $clr_lightgrey;

		transform: skew(0deg, -2deg);

		@media screen and ( $maxW_1280 )
		{
			bottom: calc(100% - 68px);
		}

		@media screen and ( $maxW_667 )
		{
			bottom: calc(100% - 87px);
		}
	}
}