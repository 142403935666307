.lecturer__list
{
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;

	width: 485px;

	@media screen and ( $maxW_1680 )
	{
		width: 470px;
	}

	@media screen and ( $maxW_1024 )
	{
		width: 455px;
	}

	@media screen and ( $maxW_667 )
	{
		flex-direction: column;
		align-items: flex-start;

		width: 100%;
	}
}

.lecturer__item
{
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	@media screen and ( $maxW_667 )
	{
		align-items: flex-end;

		width: 100%;
	}

	&:not(:last-child)
	{
		margin-bottom: 53px;

		@media screen and ( $maxW_1024 )
		{
			margin-bottom: 24px;
		}
	}
}

.lecturer__item_experience
{
	margin-right: auto;

	@media screen and ( $maxW_667 )
	{
		order: -2;

		text-align: right;
	}
}

.lecturer__item_hsb
{
	box-sizing: border-box;
	padding-left: 158px;
	padding-top: 3px;

	background: 0 6px url("/img/lecturer/hsb.svg") no-repeat;

	@media screen and ( $maxW_1024 )
	{
		padding-left: 174px;

		background-position: 0 0;
	}
}

.lecturer__item_projects
{
	margin-right: auto;

	@media screen and ( $maxW_667 )
	{
		order: -1;

		text-align: right;
	}
}

.lecturer__item_ftb
{
	box-sizing: border-box;
	padding-left: 135px;
	padding-top: 5px;

	background: 0 0 url("/img/lecturer/ftb.svg") no-repeat;

	@media screen and ( $maxW_1024 )
	{
		padding-top: 20px;
		padding-left: 148px;

		background-position: 0 10px;
	}

	@media screen and ( $maxW_667 )
	{
		padding-top: 4px;
		padding-bottom: 5px;

		background-position: 0 0;
	}
}

.lecturer__item_events
{

}

.lecturer__caption
{
	margin-bottom: -4px;

	font-size: 38px;
	line-height: 41px;
	font-weight: $bold;
	letter-spacing: -2px;
	text-transform: uppercase;

	color: $clr_lightgrey;
}

.lecturer__description
{
	font-size: 12px;
	line-height: 14px;
	font-weight: $medium;
	letter-spacing: 1.3px;
	text-transform: uppercase;

	color: $clr_lightgrey;
}