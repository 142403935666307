.promo__description_left
{
	margin-left: 100px;
	margin-bottom: 132px;

	@media screen and ( $maxW_1920 )
	{
		margin-left: calc( ( 100 - 69 ) / ( 1920 - 1280 ) * 100vw + 7px );
		margin-bottom: calc( ( 132 - 94 ) / ( 1920 - 1280 ) * 100vw + 14px );
	}

	@media screen and ( $maxW_1280 )
	{
		margin-left: 127px;
		margin-bottom: 78px;
	}

	@media screen and ( $maxW_1024 )
	{
		align-self: flex-start;

		margin-bottom: -5px;
		margin-left: 10px;
	}

	@media screen and ( $maxW_736 )
	{
		margin-bottom: -19px;
	}

	@media screen and ( $maxW_667 )
	{
		order: 4;
		align-self: center;

		margin-bottom: 28px;
	}
}