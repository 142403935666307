.reviews__background-wrapper
{
	position: absolute;
	top: 268px;
	left: calc(50% - 50vw);
	bottom: -1px;
	z-index: -1;

	overflow: hidden;

	width: 100vw;

	background: $clr_lightgrey;
}

.reviews__background
{
	position: absolute;
	top: -2vw;
	left: 0;
	z-index: -1;

	overflow: hidden;

	width: 100%;
	height: 337px;

	transform: skewY(2deg);

	&::before

	{
		position: absolute;
		top: 0;
		bottom: -2vw;
		left: 0;
		right: 0;
		z-index: -1;

		content: "";

		background: calc(50vw - 1057px / 2) calc(100% + 220px) url("/img/icon.png") no-repeat,
		calc(50vw - 1097px / 2) 100% url("/img/tonality.png") repeat,
		url("/img/pattern.svg") repeat;
		background-blend-mode: multiply,
		color-dodge,
		normal;

		transform: skewY(-2deg);
	}
}