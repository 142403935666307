.promo-text
{
	@extend %default_grid;

	margin-bottom: 100px;

	@media screen and ( $maxW_1024 )
	{
		margin-bottom: 80px;
	}
}

.promo-text_end
{
	@media screen and ( $minW_1024 )
	{
		margin-bottom: 0 !important;
	}
}