.promo__description_right
{
	align-self: flex-end;

	margin-right: 270px;
	margin-bottom: 58px;

	@media screen and ( $maxW_1920 )
	{
		margin-right: calc( ( 270 - 172 ) / ( 1920 - 1280 ) * 100vw - 24px );
		margin-bottom: calc( ( 58 - 38 ) / ( 1920 - 1280 ) * 100vw - 2px );
	}

	@media screen and ( $maxW_1280 )
	{
		margin-bottom: 49px;
		margin-right: 172px;
	}

	@media screen and ( $maxW_1024 )
	{
		align-self: flex-end;

		margin-right: 0;
		margin-bottom: 50px;

		font-size: 12px;
		line-height: 14px;
	}

	@media screen and ( $maxW_812 )
	{
		margin-bottom: 22px;
	}

	@media screen and ( $maxW_667 )
	{
		order: 5;
		align-self: center;

		margin-bottom: 0;

		font-size: 14px;
		line-height: 19px;
	}
}

.promo__description_upgrade-right
{
	margin-right: 170px;

	@media screen and ( $maxW_1920 )
	{
		margin-right: calc( ( 170 - 100 ) / ( 1920 - 1280 ) * 100vw - 40px );
	}

	@media screen and ( $maxW_1280 )
	{
		margin-right: 100px;
	}
}