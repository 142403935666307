.lecturer__photo
{
	position: absolute;
	bottom: 0;
	left: 115px;
	z-index: -1;

	width: 1397px;

	@media screen and ( $maxW_1680 )
	{
		left: 30px;
	}

	@media screen and ( $maxW_1280 )
	{
		left: -19px;
	}

	@media screen and ( $maxW_1024 )
	{
		left: 9px;

		width: 888px;
	}

	@media screen and ( $maxW_812 )
	{
		left: 12px;
	}

	@media screen and ( $maxW_736 )
	{
		left: -6px;
	}

	@media screen and ( $maxW_667 )
	{
		top: 90px;
		left: -413px;

		width: 780px;
	}

	img
	{
		display: block;

		width: 100%;
	}
}