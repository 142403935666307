.about__background
{
	position: absolute;
	top: -7vw;
	left: 0;
	z-index: -1;

	overflow: hidden;

	width: 200%;
	height: 320px;

	transform: skewY(-4deg);

	@media screen and ( $maxW_1680 )
	{
		height: 290px;
	}

	@media screen and ( $maxW_1024 )
	{
		display: none;
	}

	&::before
	{
		position: absolute;
		top: 0;
		bottom: -7vw;
		left: -1.1vw;
		right: 0;
		z-index: -1;

		content: "";

		background: calc(50vw - 1057px / 2) calc(100% + 220px) url("/img/icon.png") no-repeat,
		calc(50vw - 1097px / 2) 100% url("/img/tonality.png") repeat,
		url("/img/pattern.svg") repeat;
		background-blend-mode: multiply,
		color-dodge,
		normal;

		transform: skewY(4deg);
	}
}
