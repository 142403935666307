.reviews__list
{
	display: flex;
	align-items: flex-start;

	cursor: grab;

	transition: transform 0.3s ease-in-out;

	@media screen and ( $maxW_667 )
	{
		position: relative;
		left: -12px;
	}
}

.reviews__item
{
	position: relative;

	display: flex;
	align-items: flex-start;
	flex-direction: column;
	flex-shrink: 0;

	width: 397px;
	box-sizing: border-box;
	padding: 4px 14px 23px 14px;

	background-color: $clr_lightgrey;

	border: 3px solid $clr_orange;
	border-radius: 10px;

	user-select: none;

	&:not(last-child)
	{
		margin-right: 15px;
	}

	@media screen and ( $maxW_1024 )
	{
		width: 310px;
		height: 460px;
		padding: 5px 14px 23px 14px;
	}
}

.reviews__image-wrapper
{
	position: absolute;
	top: 0;
	left: 13px;

	overflow: hidden;

	width: 75px;
	height: 75px;

	transform: translateY(-50%);

	&::after
	{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;

		content: "";

		border: 3px solid $clr_orange;
		border-radius: 50%;
	}
}

.reviews__image
{
	width: 100%;
}

.reviews__review-caption
{
	position: relative;
	z-index: 1;

	align-self: flex-end;

	width: 271px;
	margin-bottom: 24px;
	padding-bottom: 2px;

	font-size: 18px;
	line-height: 20px;
	font-weight: $bold;
	font-style: normal;
	letter-spacing: 1.7px;
	text-transform: uppercase;

	color: $clr_lightgrey;

	&::before
	{
		position: absolute;
		top: 3px;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;

		content: "";

		background-color: $clr_orange;

		@media screen and ( $maxW_1024 )
		{
			top: 1px;
		}

		@media screen and ( $maxW_812 )
		{
			top: 2px;
		}
	}

	@media screen and ( $maxW_1024 )
	{
		width: 187px;
		padding-bottom: 9px;

		font-size: 12px;
		line-height: 14px;
		letter-spacing: 1.1px;
	}
}

.reviews__review-text
{
	margin: 0;

	font-size: 14px;
	line-height: 18px;
	font-weight: $regular;
	letter-spacing: 0.3px;

	@media screen and ( $maxW_1024 )
	{
		overflow-y: auto;
		flex-grow: 1;
	}
}