.program__text
{
	width: 413px;
	margin-top: 70px;

	font-size: 14px;
	line-height: 18px;
	font-weight: $regular;
	letter-spacing: 0.3px;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1680 )
	{
		width: 354px;
	}

	@media screen and ( $maxW_1280 )
	{
		width: 296px;
	}

	@media screen and ( $maxW_1024 )
	{
		width: 360px;
		margin-top: 18px;
	}

	@media screen and ( $maxW_812 )
	{
		width: 322px;
	}

	@media screen and ( $maxW_736 )
	{
		width: 295px;
	}

	@media screen and ( $maxW_667 )
	{
		display: none;
	}

	&:nth-of-type(1)
	{
		margin-right: 20px;

		@media screen and ( $maxW_1680 )
		{
			margin-right: 47px;
		}

		@media screen and ( $maxW_1280 )
		{
			margin-right: 22px;
		}

		@media screen and ( $maxW_1024 )
		{
			margin-right: auto;
		}
	}
}