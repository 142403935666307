.creators__text
{
	width: 847px;
	margin-bottom: 3px;

	font-size: 14px;
	line-height: 19px;
	font-weight: $medium;
	letter-spacing: 1.4px;
	text-transform: uppercase;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1680 )
	{
		width: 906px;
	}

	@media screen and ( $maxW_1280 )
	{
		width: 820px;
	}

	@media screen and ( $maxW_1024 )
	{
		width: 696px;

		font-size: 12px;
		line-height: 14px;
	}

	@media screen and ( $maxW_812 )
	{
		width: 616px;
	}

	@media screen and ( $maxW_667 )
	{
		width: 100%;
	}
}