.partners__scroll-wrapper
{
	align-self: center;

	width: 1439px;

	@media screen and ( $maxW_1680 )
	{
		width: 1234px;
	}

	@media screen and ( $maxW_1440 )
	{
		width: 100%;
	}

	@media screen and ( $maxW_1024 )
	{
		overflow: hidden;

		width: 100vw;
		height: 264px;
	}
}

.partners__list-wrapper
{
	width: 100%;

	@media screen and ( $maxW_1024 )
	{
		overflow-x: scroll;

		width: auto;
		padding-left: calc( ( 100vw - 748px ) / 2 );
		padding-bottom: 60px;
	}

	@media screen and ( $maxW_812 )
	{
		padding-left: calc( ( 100vw - 672px ) / 2 );
	}

	@media screen and ( $maxW_736 )
	{
		padding-left: calc( ( 100vw - 617px ) / 2 );
	}

	@media screen and ( $maxW_667 )
	{
		padding-left: calc( ( 100vw - 325px ) / 2 );
	}

	@media screen and ( $maxW_375 )
	{
		padding-left: calc( ( 100vw - ( 100vw - 16px * 2 ) ) / 2 );
	}
}

.partners__list
{
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and ( $maxW_1024 )
	{
		width: 992px;
	}

	&:not(:last-child)
	{
		margin-bottom: 49px;

		@media screen and ( $maxW_1440 )
		{
			margin-bottom: 38px;
		}

		@media screen and ( $maxW_1280 )
		{
			margin-bottom: 33px;
		}

		@media screen and ( $maxW_1024 )
		{
			margin-bottom: 38px;
		}
	}
}

.partners__item
{

}

.partners__img
{
	//img
	//{
	//	@media screen and ( $maxW_1440 )
	//	{
	//		transform: scale(0.85);
	//	}
	//}
}