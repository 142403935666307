$minW_667: 'min-width: 667px';
$minW_736: 'min-width: 736px';
$minW_812: 'min-width: 812px';
$minW_1024: 'min-width: 1024px';
$minW_1280: 'min-width: 1280px';
$minW_1440: 'min-width: 1440px';
$minW_1680: 'min-width: 1680px';
$minW_1920: 'min-width: 1920px';

$maxW_375: 'max-width: 374px';
$maxW_414: 'max-width: 413px';
$maxW_667: 'max-width: 666px';
$maxW_736: 'max-width: 735px';
$maxW_812: 'max-width: 811px';
$maxW_1024: 'max-width: 1023px';
$maxW_1280: 'max-width: 1279px';
$maxW_1440: 'max-width: 1439px';
$maxW_1680: 'max-width: 1679px';
$maxW_1920: 'max-width: 1919px';