.lecturer__text
{
	width: 458px;
	margin-bottom: 50px;

	font-size: 14px;
	line-height: 18px;
	font-weight: $regular;
	letter-spacing: 0.3px;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1024 )
	{
		width: 183px;
		margin-bottom: 15px;
	}

	@media screen and ( $maxW_667 )
	{
		width: 160px;
		margin-left: auto;
		margin-bottom: 135px;

		text-align: right;
	}
}
