%default_grid
{
	width: $width;
	margin: 0 auto;

	@media screen and ($maxW_1680)
	{
		width: $width_maxW_1680;
	}

	@media screen and ($maxW_1280)
	{
		width: $width_maxW_1280;
	}

	@media screen and ($maxW_1024)
	{
		width: $width_maxW_1024;
	}

	@media screen and ($maxW_812)
	{
		width: $width_maxW_812;
	}

	@media screen and ($maxW_736)
	{
		width: $width_maxW_736;
	}

	@media screen and ($maxW_667)
	{
		width: $width_maxW_667;
		max-width: $max_width_maxW_667;
	}
}