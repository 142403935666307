.tickets-short__list
{
	display: flex;
	align-items: stretch;

	width: 100%;

	@media screen and ( $maxW_1024 )
	{
		align-items: flex-start;
		justify-content: space-between;
	}

	@media screen and ( $maxW_736 ) and ( $minW_667 )
	{
		justify-content: center;
	}

	@media screen and ( $maxW_667 )
	{
		align-items: center;
		flex-direction: column;
	}
}

.tickets-short__item
{
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	padding: 0 109px;

	@media screen and ( $maxW_1680 )
	{
		padding: 0 22px 0 96px;
	}

	@media screen and ( $maxW_1280 )
	{
		padding: 0 31px 0 45px;
	}

	@media screen and ( $maxW_1024 )
	{
		position: relative;

		width: 364px;
		box-sizing: border-box;
		padding: 15px 32px 26px 37px;

		border-radius: 10px;
	}

	@media screen and ( $maxW_812 )
	{
		width: 325px;
		padding: 15px 23px 26px 17px;
	}

	@media screen and ( $maxW_736 )
	{
		flex-shrink: 0;

		width: 313px;
		padding: 15px 20px 26px 10px;
	}

	@media screen and ( $maxW_667 )
	{
		width: 100%;
		min-width: 313px;
	}

	&:nth-child(1)
	{
		@media screen and ( $maxW_1280 ) and ( $minW_1024 )
		{
			padding: 0 31px;
		}

		@media screen and ( $maxW_736 ) and ( $minW_667 )
		{
			margin-right: 15px;
		}

		@media screen and ( $maxW_667 )
		{
			margin-bottom: 30px;
		}
	}

	&:nth-child(2)
	{
		@media screen and ( $maxW_1280 ) and ( $minW_1024 )
		{
			padding: 0 24px 0 61px;
		}
	}
}

.tickets-short__item_lightgrey
{
	@media screen and ( $maxW_1024 )
	{
		background-color: $clr_lightgrey;

		border: 3px solid $clr_orange;
	}
}

.tickets-short__item_orange
{
	@media screen and ( $maxW_1024 )
	{
		background-color: $clr_orange;

		border: 3px solid $clr_white;
	}
}

.tickets-short__caption
{
	margin-bottom: -10px;

	font-size: 62px;
	line-height: 74px;
	font-weight: $bold;
	letter-spacing: 6px;
	text-transform: uppercase;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1024 )
	{
		color: $clr_black;
	}
}

.tickets-short__caption_black
{
	color: $clr_black;
}

.tickets-short__description
{
	font-size: 12px;
	line-height: 14px;
	font-weight: $medium;
	letter-spacing: 1.2px;
	text-transform: uppercase;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1024 )
	{
		color: $clr_black;
	}
}

.tickets-short__description_black
{
	color: $clr_black;
}

.tickets-short__price
{
	position: relative;

	margin-top: 50px;
	margin-bottom: 70px;

	@media screen and ( $maxW_1024 )
	{
		margin-top: 40px;
		margin-bottom: 8px;
	}
}

.tickets-short__price-current
{
	display: block;

	font-size: 62px;
	line-height: 74px;
	font-weight: $bold;
	letter-spacing: -3px;
	text-transform: uppercase;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1024 )
	{
		color: $clr_black;
	}
}

.tickets-short__price-current_black
{
	color: $clr_black;
}

.tickets-short__price-old
{
	position: absolute;
	top: 4px;
	left: calc(100% + 53px);

	content: attr(data-old-price);

	font-size: 38px;
	line-height: 41px;
	font-weight: $bold;
	letter-spacing: -3px;
	text-transform: uppercase;
	white-space: nowrap;

	color: $clr_grey;

	@media screen and ( $maxW_1280 )
	{
		left: calc(100% + 35px);
	}

	@media screen and ( $maxW_1024 )
	{
		bottom: 87%;
		left: 0;
		top: auto;

		font-size: 28px;
		line-height: 31px;
		letter-spacing: -1px;
	}

	&::before
	{
		position: absolute;
		left: -8px;
		right: -12px;
		top: -6px;
		bottom: 0;

		height: 5px;
		margin: auto 0;

		content: "";

		background-color: $clr_grey;

		@media screen and ( $maxW_1024 )
		{
			left: -2px;
			right: -4px;

			height: 3px;
		}
	}
}

.tickets-short__button
{
	align-self: center;

	margin-top: auto;

	@media screen and ( $maxW_1024 )
	{
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
}

.tickets-short__button_orange
{
	@extend %button_orange;

	@media screen and ( $maxW_1680 )
	{
		align-self: flex-start;

		margin-left: 15px;
	}

	@media screen and ( $maxW_1280 )
	{
		margin-left: 0;
	}

	@media screen and ( $maxW_1024 )
	{
		align-self: center;
	}
}

.tickets-short__button_black
{
	@extend %button_black;

	@media screen and ( $maxW_1680 )
	{
		align-self: flex-end;

		margin-right: 80px;
	}

	@media screen and ( $maxW_1280 )
	{
		margin-right: 0;
	}

	@media screen and ( $maxW_1024 )
	{
		align-self: center;
	}
}