.program__list
{
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;

	margin-top: 40px;

	@media screen and ( $maxW_1024 )
	{
		margin-top: 20px;
	}

	@media screen and ( $maxW_667 )
	{
		margin-top: 12px;
	}
}

.program__item
{
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	&:nth-child(1),
	&:nth-child(2),
	&:nth-child(3)
	{
		@media screen and ( $maxW_1024 ) and ( $minW_667 )
		{
			margin-bottom: 17px;
		}

		@media screen and ( $minW_1024 )
		{
			margin-bottom: 47px;
		}
	}

	&:not(:last-child)
	{
		@media screen and ( $maxW_667 )
		{
			margin-bottom: 17px;
		}
	}
}

.program__item_bg_hands
{
	padding-top: 32px;
	margin-right: 230px;

	background: 48px 0 url("/img/program/hands.svg") no-repeat;

	@media screen and ( $maxW_1680 )
	{
		margin-right: 145px;
	}

	@media screen and ( $maxW_1280 )
	{
		margin-right: 65px;
	}

	@media screen and ( $maxW_1024 )
	{
		padding-top: 13px;
		padding-left: 52px;
		margin-right: 105px;

		background-size: 70px;
		background-position: 0 6px;
	}

	@media screen and ( $maxW_812 )
	{
		margin-right: 54px;
	}

	@media screen and ( $maxW_736 )
	{
		margin-right: 30px;
	}

	@media screen and ( $maxW_667 )
	{
		order: -4;

		padding-left: 72px;
		margin-right: 19px;
		background-position: 24px 6px;
	}
}

.program__item_bg_fire
{
	width: 365px;
	box-sizing: border-box;
	padding-top: 60px;
	padding-left: 80px;
	margin-right: auto;

	background: 0 0 url("/img/program/fire.svg") no-repeat;

	@media screen and ( $maxW_1024 )
	{
		width: 270px;
		padding-top: 15px;
		padding-left: 51px;

		background-size: 75px;
	}

	@media screen and ( $maxW_667 )
	{
		order: -2;

		padding-top: 10px;
		padding-left: 55px;

		background-position: 13px 0;
	}
}

.program__item_bg_smile
{
	padding-top: 40px;
	padding-left: 90px;

	background: 0 10px url("/img/program/smile.svg") no-repeat;

	@media screen and ( $maxW_1024 )
	{
		padding-top: 33px;
		padding-left: 63px;

		background-size: 82px;
		background-position: 0 11px;
	}

	@media screen and ( $maxW_667 )
	{
		padding-top: 25px;
		padding-left: 114px;

		background-position: 51px 2px;
	}
}

.program__item_bg_box
{
	padding-top: 34px;
	padding-left: 47px;
	margin-right: 191px;

	background: -10px 0 url("/img/program/box.svg") no-repeat;

	@media screen and ( $maxW_1680 )
	{
		margin-right: 124px;
	}

	@media screen and ( $maxW_1280 )
	{
		margin-right: 23px;
	}

	@media screen and ( $maxW_1024 )
	{
		padding-top: 12px;
		padding-left: 42px;
		margin-right: 65px;

		background-size: 93px;
		background-position: 0 0;
	}

	@media screen and ( $maxW_812 )
	{
		margin-right: 38px;
	}

	@media screen and ( $maxW_736 )
	{
		margin-right: 19px;
	}

	@media screen and ( $maxW_667 )
	{
		order: -3;

		margin-right: 0;
	}
}

.program__item_bg_lightning
{
	width: 365px;
	box-sizing: border-box;
	padding-top: 46px;
	padding-left: 91px;
	margin-right: auto;

	background: 0 10px url("/img/program/lightning.svg") no-repeat;

	@media screen and ( $maxW_1024 )
	{
		width: 260px;
		padding-top: 8px;
		padding-left: 55px;

		background-size: 88px;
		background-position: 0 0;
	}

	@media screen and ( $maxW_667 )
	{
		order: -1;

		padding-top: 13px;
		padding-left: 80px;

		background-position: 25px 6px;
	}
}

.program__item_bg_earth
{
	padding-top: 27px;
	padding-left: 85px;

	background: 0 0 url("/img/program/earth.svg") no-repeat;

	@media screen and ( $maxW_1024 )
	{
		padding-top: 8px;
		padding-left: 55px;

		background-size: 140px;
		background-position: 0 0;
	}

	@media screen and ( $maxW_667 )
	{
		padding-top: 23px;
		padding-left: 96px;

		background-position: 38px 10px;
	}
}

.program__caption
{
	color: $clr_lightgrey;
}

.program__caption_big
{
	font-size: 99px;
	line-height: 97px;
	font-weight: $bold;
	letter-spacing: -3px;
	text-transform: uppercase;

	@media screen and ( $maxW_1024 )
	{
		font-size: 62px;
		line-height: 69px;
	}
}

.program__caption_medium
{
	font-size: 38px;
	line-height: 44px;
	font-weight: $bold;
	letter-spacing: 4px;
	text-transform: uppercase;

	@media screen and ( $maxW_1024 )
	{
		font-size: 28px;
		line-height: 31px;
		letter-spacing: -1px;
	}
}

.program__description
{
	color: $clr_lightgrey;
}

.program__description_big
{
	font-size: 28px;
	line-height: 32px;
	font-weight: $bold;
	letter-spacing: 3px;
	text-transform: uppercase;

	@media screen and ( $maxW_1024 )
	{
		font-size: 18px;
		line-height: 20px;
		letter-spacing: 2px;
	}
}

.program__description_medium
{
	font-size: 18px;
	line-height: 20px;
	font-weight: $bold;
	letter-spacing: 2px;
	text-transform: uppercase;

	@media screen and ( $maxW_1024 )
	{
		font-size: 12px;
		line-height: 14px;
		letter-spacing: 1px;
	}
}

.program__description_small
{
	font-size: 12px;
	line-height: 14px;
	font-weight: $medium;
	letter-spacing: 1.3px;
	text-transform: uppercase;

	@media screen and ( $maxW_1024 )
	{
		font-weight: $bold;
	}
}

.program__description_hands
{
	transform: translate(14px, -6px);

	@media screen and ( $maxW_1024 )
	{
		font-size: 0;
		line-height: 0;
	}

	&::before
	{
		@media screen and ( $maxW_1024 )
		{
			display: table-caption;

			content: attr(data-short);

			font-size: 12px;
			line-height: 14px;
			letter-spacing: 1px;
		}
	}
}

.program__description_fire
{
	transform: translate(-1px, -6px);

	@media screen and ( $maxW_1024 )
	{
		transform: translate(-1px, -3px);
	}
}

.program__description_box
{
	transform: translate(3px, -6px);
}

.program__description_lightning
{
	transform: translate(3px, -3px);
}

.program__description_earth
{
	transform: translate(3px, -6px);
}