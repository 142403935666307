%button_orange
{
	display: inline-block;

	padding: 11px 36px 7px 36px;

	font-size: 28px;
	line-height: 30px;
	font-weight: $bold;
	letter-spacing: -1.6px;
	text-transform: uppercase;
	white-space: nowrap;

	background-color: $clr_orange;
	color: $clr_black;

	border: 4px solid $clr_white;
	border-radius: 50px;

	transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;

	user-select: none;

	&:hover,
	&:active
	{
		@media screen and ( $minW_1024 )
		{
			border-color: $clr_orange;
		}
	}

	&:active
	{
		@media screen and ( $minW_1024 )
		{
			color: $clr_white;
		}
	}
}