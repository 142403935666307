.training__title-wrapper
{
	position: relative;

	width: 100%;
	padding-top: 125px;
	padding-bottom: 140px;
	margin-bottom: 106px;

	background: 50% 32% url("/img/training/r.svg") no-repeat;

	@media screen and ( $maxW_1680 )
	{
		margin-bottom: 110px;
	}

	@media screen and ( $maxW_1024 )
	{
		padding-top: 0;
		padding-bottom: 0;
		margin-bottom: 62px;

		background: none;
	}

	@media screen and ( $maxW_667 )
	{
		margin-bottom: 30px;
	}
}

.training__title
{
	font-size: 99px;
	line-height: 97px;
	font-weight: $bold;
	letter-spacing: -5px;
	text-transform: uppercase;
	text-align: center;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1024 )
	{
		display: none;
	}
}

.training__rules-list
{
	@media screen and ( $maxW_1024 )
	{
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}
}

.training__rules-item
{
	position: absolute;

	@media screen and ( $maxW_1024 )
	{
		position: static;

		/*width: 233px;*/
		//min-width: 316px;
		//max-width: 402px;
	}

	&:nth-child(1),
	&:nth-child(2)
	{
		@media screen and ( $maxW_1024 )
		{
			margin-bottom: 28px;
		}
	}

	&:nth-child(3)
	{
		@media screen and ( $maxW_667 )
		{
			margin-bottom: 80px;
		}
	}

	&:nth-child(1),
	&:nth-child(3)
	{
		@media screen and ( $maxW_1024 )
		{
			width: 316px;
			margin-right: 29px;
		}

		@media screen and ( $maxW_812 )
		{
			margin-right: 40px;
		}

		@media screen and ( $maxW_736 )
		{
			margin-right: 13px;
		}

		@media screen and ( $maxW_667 )
		{
			width: 100%;
			margin-right: 0;
		}
	}

	&:nth-child(2),
	&:nth-child(4)
	{
		@media screen and ( $maxW_1024 )
		{
			width: 402px;
		}

		@media screen and ( $maxW_812 )
		{
			width: 315px;
		}

		@media screen and ( $maxW_736 )
		{
			width: 287px;
		}

		@media screen and ( $maxW_667 )
		{
			width: 100%;
		}
	}

	//@media screen and ( $maxW_812 )
	//{
	//	min-width: 315px;
	//	max-width: 315px;
	//}
	//
	//@media screen and ( $maxW_736 )
	//{
	//	min-width: 315px;
	//	max-width: 315px;
	//}

	//&:nth-child(odd)
	//{
	//	@media screen and ( $maxW_1024 )
	//	{
	//		margin-right: 29px;
	//	}
	//
	//	@media screen and ( $maxW_812 )
	//	{
	//		margin-right: 40px;
	//	}
	//}
}

.training__rules-item_left
{
	left: 14.5%;
	bottom: 0;

	@media screen and ( $maxW_1680 )
	{
		left: 17%;
	}

	@media screen and ( $maxW_1280 )
	{
		left: 0;
		bottom: 1%;
	}

	&::before
	{
		position: absolute;
		bottom: 90%;
		left: 40%;

		width: 147px;
		height: 87px;

		content: "";

		background: 50% 32%/100% url("/img/training/item_left_1x.png") no-repeat;

		@media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx)
		{
			background: 50% 32%/100% url("/img/training/item_left_2x.png") no-repeat;
		}

		@media screen and ( $maxW_1680 )
		{
			bottom: 85%;
			left: 3%;
		}

		@media screen and ( $maxW_1280 )
		{
			bottom: 76%;
			left: 43%;
		}

		@media screen and ( $maxW_1024 )
		{
			content: none;
		}
	}
}

.training__rules-item_top
{
	top: 0;
	left: 40.5%;

	@media screen and ( $maxW_1680 )
	{
		left: 44%;
	}

	@media screen and ( $maxW_1280 )
	{
		left: 38%;
	}

	&::before
	{
		position: absolute;
		top: 100%;
		right: 166%;

		width: 75px;
		height: 95px;

		content: "";

		background: 50% 32%/100% url("/img/training/item_top_1x.png") no-repeat;

		@media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx)
		{
			background: 50% 32%/100% url("/img/training/item_top_2x.png") no-repeat;
		}

		@media screen and ( $maxW_1680 )
		{
			top: 109%;
			right: 191%;
		}

		@media screen and ( $maxW_1280 )
		{
			top: 109%;
			right: 171%;
		}

		@media screen and ( $maxW_1024 )
		{
			content: none;
		}
	}
}

.training__rules-item_right
{
	bottom: 5%;
	right: 0;

	@media screen and ( $maxW_1680 )
	{
		bottom: 5%;
	}

	@media screen and ( $maxW_1280 )
	{
		bottom: 2%;
	}

	&::before
	{
		position: absolute;
		bottom: 111%;
		left: 50%;

		width: 71px;
		height: 169px;

		content: "";

		background: 50% 32%/100% url("/img/training/item_right_1x.png") no-repeat;

		@media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx)
		{
			background: 50% 32%/100% url("/img/training/item_right_2x.png") no-repeat;
		}

		@media screen and ( $maxW_1680 )
		{
			bottom: 111%;
			left: 79%;
		}

		@media screen and ( $maxW_1280 )
		{
			bottom: 97%;
			left: 45%;
		}

		@media screen and ( $maxW_1024 )
		{
			content: none;
		}
	}
}

.training__rules-item_mobile
{
	margin-top: auto;

	@media screen and ( $minW_1024 )
	{
		display: none;
	}
}

.training__rules-caption
{
	position: absolute;
	top: -3px;
	right: calc(100% + 15px);

	font-size: 38px;
	line-height: 41px;
	font-weight: $bold;
	letter-spacing: -2px;
	text-transform: uppercase;
	text-align: right;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1024 )
	{
		position: static;

		font-size: 28px;
		line-height: 31px;
		text-align: left;
	}
}

.training__rules-caption_left
{
	@media screen and ( $maxW_1280 ) and ( $minW_1024 )
	{
		top: auto;
		bottom: 100%;
		right: auto;
		left: 0;
	}
}

.training__rules-caption_right
{
	@media screen and ( $maxW_1280 ) and ( $minW_1024 )
	{
		top: auto;
		bottom: 100%;
		right: 0;
		left: auto;

		text-align: right;
	}
}

.training__rules-text
{
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	font-size: 14px;
	line-height: 19px;
	font-weight: $medium;
	letter-spacing: 1.5px;
	text-transform: uppercase;

	span
	{
		position: relative;
		z-index: 1;

		white-space: nowrap;

		&::before
		{
			position: absolute;
			z-index: -1;

			width: 100%;
			height: 85%;

			content: "";

			background-color: $clr_lightgrey;
		}
	}
}

.training__rules-text_top
{
	span
	{
		&:nth-child(1)
		{
			&::before
			{
				top: -5%;
				left: -1%;
				width: 103%;
			}
		}

		&:nth-child(2)
		{
			&::before
			{
				left: -2.5%;
				width: 106%;
			}
		}

		&:nth-child(3)
		{
			&::before
			{
				left: -1%;
				width: 118%;
			}
		}

		&:nth-child(4)
		{
			&::before
			{
				left: -4%;
				width: 120%;
			}
		}
	}
}

.training__rules-text_left
{
	span
	{
		&:nth-child(1)
		{
			&::before
			{
				top: -5%;
				left: -2%;
				width: 103%;
			}
		}

		&:nth-child(2)
		{
			&::before
			{
				top: 0;
				left: -1%;
				width: 100%;
			}
		}

		&:nth-child(3)
		{
			&::before
			{
				top: 0;
				left: -2%;
				width: 100%;
			}
		}

		&:nth-child(4)
		{
			&::before
			{
				top: 0;
				left: -2%;
				width: 102%;
			}
		}
	}
}

.training__rules-text_right
{
	@media screen and ( $maxW_1280 ) and ( $minW_1024 )
	{
		align-items: flex-end;
	}

	span
	{
		&:nth-child(1)
		{
			&::before
			{
				top: -5%;
				left: -1.5%;
				width: 100%;

				@media screen and ( $maxW_1280 ) and ( $minW_1024 )
				{
					left: 0;
					width: 101%;
				}
			}
		}

		&:nth-child(2)
		{
			&::before
			{
				top: -2px;
				left: -1%;
				width: 100%;
				height: 102%;

				@media screen and ( $maxW_1280 ) and ( $minW_1024 )
				{
					top: -1px;
					left: -1%;
					width: 101.5%;
					height: 96%;
				}
			}
		}

		&:nth-child(3)
		{
			&::before
			{
				top: 0;
				left: 0;
				width: 103%;

				@media screen and ( $maxW_1280 ) and ( $minW_1024 )
				{
					top: 0;
					left: -4%;
					width: 103.5%;
				}
			}
		}

		&:nth-child(4)
		{
			&::before
			{
				top: -1px;
				left: 0;
				width: 102%;
				height: 91%;

				@media screen and ( $maxW_1280 ) and ( $minW_1024 )
				{
					top: 0;
					left: -2%;
				}
			}
		}
	}
}