.training__description-list
{
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	width: 100%;
	margin-bottom: 87px;

	@media screen and ( $maxW_1680 )
	{
		margin-bottom: 97px;
	}

	@media screen and ( $maxW_1280 )
	{
		margin-bottom: 70px;
	}

	@media screen and ( $maxW_1024 )
	{
		display: none;
	}
}
