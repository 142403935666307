.promo__description
{
	display: flex;
	flex-direction: column;

	font-size: 20px;
	line-height: 27px;
	font-weight: $medium;
	text-transform: uppercase;
	letter-spacing: 2px;

	@media screen and ( $maxW_1920 )
	{
		font-size: calc( ( 20 - 14 ) / ( 1920 - 1280 ) * 100vw + 2px );
		line-height: calc( ( 27 - 19 ) / ( 1920 - 1280 ) * 100vw + 3px );
	}

	@media screen and ( $maxW_1280 )
	{
		font-size: 14px;
		line-height: 19px;
		letter-spacing: 1.5px;
	}

	@media screen and ( $maxW_1024 )
	{
		font-size: 14px;
		line-height: 19px;
	}

	span
	{
		&[data-short]
		{
			@media screen and ( $maxW_1024 )
			{
				position: relative;

				font-size: 0;
			}

			&::before
			{
				@media screen and ( $maxW_1024 )
				{
					position: absolute;
					top: 0;
					left: 0;

					content: attr(data-short);

					font-size: 12px;
					line-height: 14px;
				}

				@media screen and ( $maxW_667 )
				{
					font-size: 14px;
					line-height: 19px;
				}
			}
		}
	}
}

.promo__description_upgrade
{
	@media screen and ( $minW_667 ) and ($maxW_1024)
	{
		margin-bottom: -7px;
	}

	@media screen and ( $minW_667 )
	{
		margin-top: 88px;
	}

	@media screen and ( $minW_736 )
	{
		margin-top: 62px;
	}

	@media screen and ( $minW_812 )
	{
		margin-top: 69px;
	}

	@media screen and ( $minW_1024 )
	{
		margin-top: 111px;
	}

	@media screen and ( $minW_1280 )
	{
		margin-top: calc( ( 161 - 111 ) / ( 1920 - 1280 ) * 100vw + 11px );
	}

	@media screen and ($minW_1920)
	{
		margin-top: 161px;
	}
}

.promo__description_end
{
	@media screen and ( $maxW_667 )
	{
		order: -1 !important;

		margin-bottom: -91px !important;
	}
}

.promo__description_right-end
{
	@media screen and ( $maxW_1024 )
	{
		margin-bottom: 0 !important;
	}

	@media screen and ( $maxW_667 )
	{
		margin-bottom: -50px !important;
	}
}