.promo__wrapper
{
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex-shrink: 0;

	padding-top: 86px;
	padding-bottom: 65px;
	width: 1709px;
	margin: 0 auto;

	@media screen and ( $maxW_1920 )
	{
		width: calc( ( 1709 - 1178 ) / ( 1920 - 1280 ) * 100vw + 116px );
		padding-top: calc( ( 86 - 54 ) / ( 1920 - 1280 ) * 100vw + -11px );
	}

	@media screen and ( $maxW_1280 )
	{
		width: 1178px;
		padding-top: 57px;
	}

	@media screen and ( $maxW_1024)
	{
		width: $width_maxW_1024;
		padding-top: 16px;
	}

	@media screen and ( $maxW_812 )
	{
		width: $width_maxW_812;
	}

	@media screen and ( $maxW_736 )
	{
		width: $width_maxW_736;
	}

	@media screen and ( $maxW_667 )
	{
		width: $width_maxW_667;
		max-width: $max_width_maxW_667;
		padding-top: 16px;
		padding-bottom: 105px;
	}
}

.promo__wrapper_end
{
	@media screen and ( $maxW_667 )
	{
		padding-top: 40px !important;
	}
}