.lecturer__photos-collection
{
	position: absolute;
	bottom: 0;
	left: 50%;
	right: 0;
	z-index: 2;

	width: 1683px;
	margin: 0 auto;

	transform: translateX(-50%);

	@media screen and ( $maxW_1024 )
	{
		display: none;
	}
}

.lecturer__photos-collection-item
{
	position: absolute;

	&:nth-child(1)
	{
		top: -115px;
		left: 33px;
		z-index: 1;

		width: 364px;

		transform: scale(-1,1);
	}

	&:nth-child(2)
	{
		top: -75px;
		left: 169px;
		z-index: 3;

		width: 445px;
	}

	&:nth-child(3)
	{
		top: -192px;
		left: 320px;
		z-index: 2;

		width: 627px;
	}

	&:nth-child(4)
	{
		top: -2px;
		left: 692px;
		z-index: 4;

		width: 371px;
	}

	&:nth-child(5)
	{
		top: -172px;
		right: 172px;
		z-index: 5;

		width: 526px;
	}

	&:nth-child(6)
	{
		top: -80px;
		right: -33px;
		z-index: 6;

		width: 371px;

		transform: scale(-1,1);
	}

	img
	{
		display: block;

		width: 100%;
	}
}