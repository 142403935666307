//.modules__list-wrapper
//{
//	transition: transform 0.3s ease-in-out;
//
//	@media screen and ( $maxW_1024 )
//	{
//		width: 100%;
//
//		transform: none !important;
//	}
//}

.modules__list
{
	display: flex;
	align-items: stretch;

	cursor: grab;

	transition: transform 0.3s ease-in-out;

	@media screen and ( $maxW_375 )
	{
		position: relative;
		left: -12px;
	}
}

.modules__list_top
{
	margin-top: 35px;

	@media screen and ( $maxW_1024 )
	{
		margin-top: 10px;
	}

	@media screen and ( $maxW_667 )
	{
		margin-top: 20px;
	}
}

.modules__list_bottom
{
	margin-top: 15px;
}

.modules__item
{
	position: relative;

	flex-shrink: 0;

	width: 313px;
	box-sizing: border-box;
	padding: 54px 14px 92px 14px;

	background-color: $clr_lightgrey;
	background-repeat: no-repeat;
	background-position: center calc(100% - 10px);

	border: 3px solid $clr_orange;
	border-radius: 10px;

	user-select: none;

	&:not(:last-child)
	{
		margin-right: 15px;
	}
}

.modules__item_orange
{
	background-color: $clr_orange;

	border-color: $clr_lightgrey;
}

.modules__item_motivation
{
	background-image: url("/img/modules/motivation.svg");
}

.modules__item_relaxing
{
	background-image: url("/img/modules/relaxing.svg");
}

.modules__item_medium
{
	background-image: url("/img/modules/medium.svg");
}

.modules__item_medium-white
{
	background-image: url("/img/modules/medium_white.svg");
}

.modules__item_hard
{
	background-image: url("/img/modules/hard.svg");
}

.modules__pre-caption
{
	position: absolute;
	top: 7px;
	left: 6px;
	right: 6px;

	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	padding-bottom: 5px;
	margin-bottom: 27px;

	font-size: 12px;
	line-height: 14px;
	font-weight: $medium;
	letter-spacing: 1px;
	text-transform: uppercase;

	background-color: $clr_orange;
	color: $clr_lightgrey;
}

.modules__pre-caption_black
{
	background-color: $clr_black;
}

.modules__name,
.modules__date
{
	transform: translateY(-2px);

	@media screen and ($maxW_667)
	{
		transform: translateY(-1px);
	}
}

.modules__caption
{
	margin-bottom: 9px;

	font-size: 18px;
	line-height: 20px;
	font-weight: $bold;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.modules__caption_white
{
	color: $clr_lightgrey;
}

.modules__markup
{
	margin-bottom: 9px;

	font-size: 14px;
	line-height: 18px;
	font-weight: $regular;
	letter-spacing: 0.2px;
}

.modules__markup-list
{

}

.modules__markup-item
{
	font-size: 14px;
	line-height: 18px;
	font-weight: $regular;
	letter-spacing: 0.2px;

	&::before
	{
		display: inline;

		content: "—";
	}
}