.training
{
	@extend %default_grid;

	display: flex;
	flex-direction: column;
	align-items: flex-start;

	padding-top: 52px;
	margin-bottom: 110px;

	@media screen and ( $maxW_1680 )
	{
		padding-top: 10px;
		margin-bottom: 134px;
	}

	@media screen and ( $maxW_1280 )
	{
		margin-bottom: 108px;
	}

	@media screen and ( $maxW_1024 )
	{
		padding-top: 0;
		margin-bottom: 78px;
	}

	@media screen and ( $maxW_667 )
	{
		//padding-top: 40px;
	}
}