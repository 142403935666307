.modules__background
{
	position: absolute;
	top: 351px;
	left: calc(50% - 50vw);
	z-index: -1;

	overflow: hidden;

	width: 200vw;
	height: 685px;

	transform: skewY(2deg);

	@media screen and ( $maxW_1680 )
	{
		top: 324px;
	}

	@media screen and ( $maxW_1024 )
	{
		display: none;
	}

	&::before
	{
		position: absolute;
		top: -4.5vw;
		bottom: 0;
		left: -1.1vw;
		right: 0;
		z-index: -1;

		content: "";

		background: calc(50vw - 1057px / 2) 100% url("/img/icon.png") no-repeat,
		calc(50vw - 1097px / 2) 50% url("/img/tonality.png") repeat,
		url("/img/pattern.svg") repeat;
		background-blend-mode: multiply,
		color-dodge,
		normal;

		transform: skewY(-2deg);
	}
}
