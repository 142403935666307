.reviews__text
{
	margin-bottom: 92px;

	font-size: 12px;
	line-height: 14px;
	font-weight: $medium;
	letter-spacing: 1.2px;
	text-transform: uppercase;

	color: $clr_grey;

	@media screen and ( $maxW_1024 )
	{
		margin-bottom: 64px;
	}

	@media screen and ( $maxW_667 )
	{
		margin-bottom: 60px;
	}
}