.training__description-item
{
	width: 277px;

	@media screen and ( $maxW_1680 )
	{
		width: 232px;
	}

	@media screen and ( $maxW_1280 )
	{
		width: 215px;
	}
}