.promo__description_black
{
	span
	{
		position: relative;
		z-index: 1;

		color: $clr_lightgrey;

		&:nth-child(1)
		{
			&::before
			{
				top: 0;
				left: -4%;

				width: 122%;

				@media screen and ( $maxW_1024 )
				{
					width: 107%;
				}
			}
		}

		&:nth-child(2)
		{
			&::before
			{
				top: 3px;
				left: -2.5%;

				width: 117%;

				@media screen and ( $maxW_1680 )
				{
					top: 1%;
				}

				@media screen and ( $maxW_1280 )
				{
					top: 1px;
				}

				@media screen and ( $maxW_1024 )
				{
					width: 102%;
				}
			}
		}

		&:nth-child(3)
		{
			&::before
			{
				top: 3px;
				left: -3%;

				width: 121%;

				@media screen and ( $maxW_1680 )
				{
					top: 1%;
				}

				@media screen and ( $maxW_1280 )
				{
					top: 1px;
				}

				@media screen and ( $maxW_1024 )
				{
					width: 104%;
				}
			}
		}

		&:nth-child(4)
		{
			&::before
			{
				top: 6px;
				left: -4%;

				width: 120%;

				@media screen and ( $maxW_1680 )
				{
					top: 9%;
				}

				@media screen and ( $maxW_1280 )
				{
					top: 2px;
				}

				@media screen and ( $maxW_1024 )
				{
					width: 104%;
				}
			}
		}

		&::before
		{
			position: absolute;
			z-index: -1;

			width: 100%;
			height: 85%;

			content: "";

			background-color: $clr_black;
		}
	}
}