.promo__photos
{
	position: absolute;
	top: 0;
	//bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;

	height: 1110px;

	@media screen and ($maxW_1920)
	{
		height: calc( ( 1110 - 783 ) / ( 1920 - 1280 ) * 100vw + 129px );
		//height: 783px;
	}

	@media screen and ($maxW_1280)
	{
		//height: calc( ( 1110 - 800 ) / ( 1920 - 1280 ) * 100vw + 180px );
		height: 800px;
	}

	@media screen and ($maxW_1024)
	{
		height: 577px;
	}

	@media screen and ($maxW_812)
	{
		height: calc( ( 577 - 520 ) / ( 812 - 667 ) * 100vw + 258px );
		//height: 520px;
	}

	@media screen and ($maxW_667)
	{
		height: 950px;
	}

	@media screen and ($maxW_414)
	{
		height: calc( ( 950 - 872 ) / ( 414 - 320 ) * 100vw + 607px );
		//height: 872px;
	}
}

.promo__img
{
	position: absolute;

	transition: transform 0.2s ease-in-out;

	&:nth-child(1)
	{
		top: 40%;
		left: 3.5%;
		z-index: 2;

		width: 35%;

		@media screen and ( $maxW_1024 )
		{
			top: 33%;
			left: 2%;
		}

		@media screen and ( $maxW_667 )
		{
			top: 42%;
			left: 6%;

			width: 91%;
		}
	}

	&:nth-child(2)
	{
		top: 32%;
		left: 1%;
		z-index: 3;

		width: 36%;

		@media screen and ( $maxW_1024 )
		{
			top: 27%;
		}

		@media screen and ( $maxW_667 )
		{
			top: 57%;
			left: -6%;

			width: 112%;
		}
	}

	&:nth-child(3)
	{
		top: 7%;
		left: 31.5%;
		z-index: 1;

		width: 28.5%;

		@media screen and ( $maxW_667 )
		{
			display: none;
		}
	}

	&:nth-child(4)
	{
		top: 18%;
		left: 57%;

		width: 44%;

		@media screen and ( $maxW_1024 )
		{
			top: 16%;
			left: 56%;
		}

		@media screen and ( $maxW_667 )
		{
			top: 29%;
			left: -1%;

			width: 106%;
		}
	}

	img
	{
		display: block;

		width: 100%;
	}
}