.about__additional-text
{
	position: relative;
	z-index: 1;

	margin-bottom: 10px;
	padding: 20px 60px 16px 60px;

	font-size: 22px;
	line-height: 24px;
	font-weight: $bold;
	letter-spacing: 3px;
	text-transform: uppercase;
	text-align: center;

	@media screen and ( $maxW_1680 )
	{
		font-size: 18px;
		line-height: 20px;
	}

	@media screen and ( $maxW_1440 )
	{
		padding: 13px 30px 11px 30px;
	}

	@media screen and ( $maxW_1024 )
	{
		font-size: 15px;
		line-height: 17px;
		letter-spacing: 1.5px;
	}

	@media screen and ( $maxW_812 )
	{
		padding: 8px 35px 10px 35px;

		font-size: 11px;
		line-height: 13px;
	}

	@media screen and ( $maxW_667 )
	{
		width: 288px;
		box-sizing: border-box;
		padding: 2px 10px 0 10px;

		font-size: 12px;
		line-height: 14px;
		font-weight: $medium;
	}

	&::before
	{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: -40px;
		z-index: -1;

		content: "";

		background-color: $clr_orange;

		transform: rotate(1deg);

		@media screen and ( $maxW_1440 )
		{
			right: 0;
		}

		@media screen and ( $maxW_1024 )
		{
			right: -40px;
		}

		@media screen and ( $maxW_667 )
		{
			right: 0;
		}
	}
}