.photos
{
	position: relative;

	align-self: center;

	width: 100%;
	min-width: 1370px;
	max-width: 1920px;

	@media screen and ( $maxW_1024 )
	{
		display: none;
	}
}

.photos__img
{
	position: absolute;

	transition: transform 0.2s ease-in-out;

	&:nth-child(1)
	{
		top: -60px;
		left: 0;

		width: 28.249%;
	}

	&:nth-child(2)
	{
		top: -46px;
		left: 24.6%;

		width: 22.628%;
	}

	&:nth-child(3)
	{
		top: -96px;
		left: 34.3%;
		z-index: 2;

		width: 19.125%;
	}

	&:nth-child(4)
	{
		top: -57px;
		left: 47%;

		width: 16.57%;
	}

	&:nth-child(5)
	{
		top: -84px;
		left: 58.5%;

		width: 26.351%;
	}

	&:nth-child(6)
	{
		top: -52px;
		left: 78%;

		width: 22.628%;
	}

	img
	{
		display: block;

		width: 100%;
	}
}