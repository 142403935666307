.promo__caption
{
	position: relative;
	z-index: 2;

	margin-bottom: 7px;

	font-size: 252px;
	line-height: 215px;
	font-weight: $bold;
	text-transform: uppercase;
	letter-spacing: -11px;

	color: $clr_orange;

	@media screen and ( $maxW_1920 )
	{
		font-size: calc( ( 252 - 174 ) / ( 1920 - 1280 ) * 100vw + 18px );
		line-height: calc( ( 215 - 148 ) / ( 1920 - 1280 ) * 100vw + 14px );
	}

	@media screen and ( $maxW_1280 )
	{
		font-size: 174px;
		line-height: 148px;
		letter-spacing: -6px;
	}

	@media screen and ( $maxW_1024 )
	{
		font-size: 140px;
		line-height: 153px;
		letter-spacing: -1px;
	}

	@media screen and ( $maxW_812 )
	{
		margin-bottom: -1px;

		font-size: calc( ( 140 - 115 ) / ( 812 - 667 ) * 100vw + 0px );
		line-height: calc( ( 153 - 128 ) / ( 812 - 667 ) * 100vw + 13px );
	}

	@media screen and ( $maxW_736 )
	{
		margin-bottom: 2px;
	}

	@media screen and ( $maxW_667 )
	{
		margin-bottom: -2px;

		font-size: 70px;
		line-height: 78px;
	}

	@media screen and ( $maxW_414 )
	{
		font-size: calc( ( 70 - 59 ) / ( 414 - 320 ) * 100vw + 21px );
		line-height: calc( ( 78 - 66 ) / ( 414 - 320 ) * 100vw + 25px );
	}
}