.promo-text__text
{
	display: block;

	font-size: 38px;
	line-height: 44px;
	font-weight: $bold;
	letter-spacing: 3.5px;
	text-transform: uppercase;
	text-align: center;

	color: $clr_lightgrey;

	@media screen and ( $maxW_1024 )
	{
		font-size: 28px;
		line-height: 32px;
	}

	@media screen and ( $maxW_667 )
	{
		font-size: 18px;
		line-height: 20px;
	}
}